/**
 * Tests Identifiers
 */
export const TEST_IDS = {
  config: {
    root: 'data-testid config',
  },
  plugins: {
    root: 'data-testid root-page plugins',
  },
};
